import React from 'react';
import './App.css';
import CreditPanel from "./CreditPanel";
import {Link} from "react-router-dom";
import SocialMedia from "./SocialMedia";

function App() {
    return (
        <header className="App-header">
            <div className="MainPanel" style={{textAlign: "center"}}>
                <div style={{fontSize: '30px'}}>
                    Tractors & Tanks
                </div>
                <div style={{marginBottom: '25px'}}>
                    NFT's supporting Ukraine!
                </div>

                <div>
                    <img alt="" src="resistance-flag.png" />
                </div>

                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                    <Link to="/background">Background</Link>&nbsp;-&nbsp;
                    <Link to="/about">About</Link>&nbsp;-&nbsp;
                    <Link to="/roadmap">Roadmap</Link>&nbsp;-&nbsp;
                    <Link to="/more">More</Link>
                </div>

                <SocialMedia />

                <CreditPanel/>
            </div>
        </header>
    );
}

export default App;
