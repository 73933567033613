import {Link} from "react-router-dom";

export default function Roadmap() {
    return (
        <div className="App-header">
            <div className="MainPanel">
                <h1>Roadmap</h1>
                <p>Phase 1:</p>
                <ul>
                    <li>Setup the website and social media accounts</li>
                    <li>Collaborate with recipient organizations</li>
                    <li>Start our marketing and social media campaign</li>
                    <li>Mint 5000 tractors</li>
                    <li>Donate the net proceeds to the Ukrainian Army</li>
                </ul>
                <p>Phase 2:</p>
                <ul>
                    <li>Develop a blockchain based tractor and tanks game</li>
                    <li>Create three sets of 5000 NFTs, representing Russian military hardware</li>
                    <li>“Steal” the Russian military hardware with your tractor!</li>
                    <li>Share "stolen" tank images on popular social media platforms</li>
                </ul>
                <p>Phase 3:</p>
                <ul>
                    <li>Burn a Tank and Tractor NFT to receive a single image</li>
                    <li>Partnerships and collaboration to expand the concept</li>
                </ul>
                <p>
                    <Link to="/">&lt; Back</Link>
                </p>
            </div>
        </div>
    )
}
