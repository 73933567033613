import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Background from "./Background";
import Roadmap from "./Roadmap";
import More from "./More";
import About from "./About";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/background" element={<Background/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/roadmap" element={<Roadmap/>}/>
                <Route path="/more" element={<More/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
