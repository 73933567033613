import {Link} from "react-router-dom";

export default function About() {
    return (
        <div className="App-header">
            <div className="MainPanel">
                <h1>About</h1>
                <p>
                    <b>What's an NFT?</b>
                </p>
                <p>
                    An <b>NFT</b> is a piece of a unique digital artwork collection that you can
                    own. Just like works of art, they can be purchased, resold or kept forever.
                </p>
                <p>
                    Our project will use an acclaimed Ukrainian artist to create 5000 NFT's in the Tractors & Tanks
                    collection. They will be available for sale at a future date. All of our profits will be donated to
                    organisations supporting the Ukrainian Army!
                </p>
                <p>
                    In order to buy one of these NFT's, you need to purchase the Solana crypto-currency using an
                    exchange or crypto-wallet. In the future we will announce mint date and price in SOL.
                </p>
                <p>
                    <b>Team</b>
                </p>
                <p>
                    Alex is an American, based in Lviv who decided to stay. Leaving was the easy option. Democracy
                    requires participation of all citizens and residents. When the war started, Alex started helping
                    those defending Ukraine - the Ukrainian Army and the home guard. </p>
                <p>
                    Teaming up with his friend tr0n, Alex is working hard to make the Tractors & Tanks project a
                    successful fundraiser for the Ukrainian military who are bravely defending their country.
                </p>
                <p>
                    <Link to="/">&lt; Back</Link>
                </p>
            </div>
        </div>
    )
}