import {Link} from "react-router-dom";

export default function Background() {
    return (
        <div className="App-header">
            <div className="MainPanel">
                <h1>Background</h1>
                <p>
                    In late February 2022, Russia invaded Ukraine without provocation. The Ukrainian army, defying the
                    odds, continues to bravely defend their homeland.
                </p>
                <p>
                    Since the early days of the war, Ukrainian farmers have been stealing Russian tanks, APVs
                    and other types of military equipment at every opportunity. The memes are all over the internet and
                    in pop culture. Hence, these unlikely heroes (with their tractors!) have become iconic symbols of
                    resistance and defiance.
                </p>
                <p>
                    With the Tractors & Tanks NFT project, we aim to immortalize the brave actions of the Ukrainian
                    farmer and make a significant financial contribution to the war effort. We will donate all profits
                    from the project to official organizations that directly support the Ukrainian Army and agricultural
                    sector.
                </p>
                <p>
                    <Link to="/">&lt; Back</Link>
                </p>
            </div>
        </div>
    )
}
