import React from "react";

export default function SocialMedia() {
    return (
        <div style={{marginTop: '25px', display: 'flex', justifyContent: 'center', gap: '20px'}}>
            <div>
                <a href="https://twitter.com/TractorsTanks/" target="twitter"><img alt="twitter" src="twitter-follow-180.jpg" /></a>
            </div>
            <div>
                <a href="https://www.instagram.com/tractors_and_tanks/" target="instagram"><img alt="instagram" src="instagram-follow-180.jpg" /></a>
            </div>
        </div>
    )
}