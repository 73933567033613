import {Link} from "react-router-dom";

export default function More() {
    return (
        <div className="App-header">
            <div className="MainPanel">
                <h1>More</h1>
                <p>
                    Firstly, you can help raise money for the Ukrainian Army now by contributing
                    to <a href="https://www.comebackalive.in.ua/" target="comebackalive">Come Back Alive</a> and,
                    in the future, by purchasing one of our NFTs. 100% of the profits from this project will go directly
                    to the Ukrainian army.
                </p>
                <p>
                    Secondly, spread the word on social media! Tell the world about Tractors & Tanks, sharing
                    our <a href="http://www.tractorsandtanks.com" target="tandt">website</a>, <a href="https://twitter.com/TractorsTanks" target="twitter">Twitter</a> and <a href="https://www.instagram.com/tractors_and_tanks/" target="instagram">Instagram</a>.
                </p>
                <p>
                    Lastly, do you have a large following? Can you help promote us? We need social media influencers, in
                    the crypto space, to help raise awareness for our NFT initiative. If you would like to partner with
                    us, please contact Alex via Telegram at @tractors_and_tanks.
                </p>
                <p>
                    Keep checking this website, our Twitter and our Instagram for updates.
                </p>
                <p>
                    <Link to="/">&lt; Back</Link>
                </p>
            </div>
        </div>
    )
}
